<template>
 <div></div>
</template>

<script>
export default {
  name: 'AppleRegistration',
  data () {
    return {
      appleToken: {
        accessToken: null,
        refreshToken: null
      }
    }
  },
  created () {
    this.appleToken.token = this.$route.query.token
    this.appleToken.refreshToken = this.$route.query.refreshToken
  },
  mounted () {
    this.lockScreen()
    this.$store.dispatch('loginApple', this.appleToken)
      .then(() => {
        this.$router.push({ name: 'dashboard' })
      })
      .catch(() => {
        this.$router.push({ name: 'login' })
      })
  }

}
</script>
